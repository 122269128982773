import { API, Axios } from '@raylink/common/utils'
import { ProductId, UnitType } from '../config'

const superVipIdList = [ProductId.superVipOneMonth, ProductId.superVipOneYear] // 1: 一年超级性能会员 2: 两年超级性能会员 3: 三年超级性能会员
const globalVipIdList = [
  ProductId.globalVipOneYear,
  ProductId.globalVipOneMonth,
] // 6: 一年全球会员 7: 两年全球会员 8: 三年全球性能会员
const eliteVipIdList = [ProductId.eliteVipOneYear] // 9: 一年菁英会员 10: 两年菁英会员 11: 三年菁英会员

const toPricingData = (pricingList, idList) => {
  return pricingList
    .filter(({ productId }) => idList.includes(productId))
    .map(({ productId, price, originalPrice, duration, unit, productNo }) => ({
      label: unit === UnitType.month ? '单月' : `${duration}年`,
      price: price.toString(),
      vipType: productNo,
      originalPrice: String(originalPrice),
      productId,
      unit,
      key: productId,
    }))
    .reverse()
}

export async function queryPricingList() {
  const instance = new Axios(API.auth.host)
  const resp = await instance.get(API.price.queryPricingList)
  if (resp?.code === 200 && resp.data) {
    const { data } = resp
    const superVipPricingList = toPricingData(data, superVipIdList)
    const globalVipPricingList = toPricingData(data, globalVipIdList)
    const eliteVipPricingList = toPricingData(data, eliteVipIdList)

    return {
      superVip: superVipPricingList,
      globalVip: globalVipPricingList,
      eliteVip: eliteVipPricingList,
    }
  }
  return {}
}
