import React, { useEffect, useRef, useState } from 'react'
import { Layout, SEO } from '@raylink/common/components'
import {
  BannerWrapper,
  Title,
  PriceTabsWrapper,
  BgImage,
  BannerContent,
  PriceWrap,
  PriceItem,
  ItemTop,
  PriceNumWrap,
  PriceBtn,
  FeatureWrap,
  CompareWrap,
  QuestionWrap,
} from './index.style'
// import moreImg from '../../images/price/moreBottom.png'
import Check from '../../svg/check'
import TipImg from '../../images/tip-icon.svg'
import HotImg from '../../images/hot.svg'
import DollarImg from '../../images/price/dollar.svg'
import {
  priceItemList,
  PriceType,
  fullItemList,
  compareList,
  titleList,
  questionList,
  ProductId,
  UnitType,
} from './config'
import CompareTable from './components/compareTable'
import PriceTabs from './components/price-tabs'
import FullContainer from './components/full-container'
import { normalizeTableList } from './utils/normalize-table-list'
import { queryPricingList } from './utils/query-pricing-list'
import SchoolOpenTitleImg from '../../images/price/openSchoolSeason/title.webp'
import SchoolOpenTitleIpadImg from '../../images/price/openSchoolSeason/titleIpad.webp'
import SchoolOpenTitlePhoneImg from '../../images/price/openSchoolSeason/titlePhone.webp'
import EllipseLeftImg from '../../images/price/openSchoolSeason/ellipse_left.svg'
import EllipseRightImg from '../../images/price/openSchoolSeason/ellipse_right.svg'
import EllipseMobileLeftImg from '../../images/price/openSchoolSeason/ellipse_left_mobile.svg'
import EllipseMobileRightImg from '../../images/price/openSchoolSeason/ellipse_right_mobile.svg'

export default function PricePage() {
  const [pricingData, setPricingData] = useState(priceItemList)
  const [productId, setProductId] = useState({
    eliteVip: ProductId.eliteVipOneYear,
    superVip: ProductId.superVipOneYear,
    globalVip: ProductId.globalVipOneYear,
  })
  const pricingTabRef = useRef([])
  useEffect(() => {
    const setPricingCardData = (cardDataList, vipType, vipPricingData) => {
      const cardData = cardDataList.find(cd => cd.key === vipType)
      cardData.priceList = vipPricingData
    }
    queryPricingList().then(({ superVip, globalVip }) => {
      if (superVip) {
        setPricingCardData(pricingData, PriceType.superVip, superVip)
      }
      if (globalVip) {
        setPricingCardData(pricingData, PriceType.globalVip, globalVip)
      }
      // TODO: 菁英会员价格暂时写死，后面后管活动管理加了配置再从街口拿
      setPricingData([...pricingData])
      // 获取地址栏参数，如果是banner入口，设置为一个月的价格
      if (typeof window === 'undefined') return
      const url = new URL(window.location.href)
      const isBannerEntry = url.searchParams.get('type') === 'banner'
      if (isBannerEntry) {
        setProductId(pre => ({
          ...pre,
          superVip: ProductId.superVipOneMonth,
          globalVip: ProductId.globalVipOneMonth,
        }))
      }
    })
  }, [])
  useEffect(() => {
    // 价格tab动画
    setTimeout(() => {
      pricingTabRef.current.forEach(el => {
        if (!el) return
        el.style.opacity = 1
      })
    }, 300)
  }, [productId])

  const getVipType = key => {
    let type
    switch (key) {
      case PriceType.globalVip:
        type = 'globalVip'
        break
      case PriceType.eliteVip:
        type = 'eliteVip'
        break
      case PriceType.superVip:
      default:
        type = 'superVip'
        break
    }
    return type
  }

  const getPricing = item => {
    if (item.key === PriceType.free) return {}
    const prop = getVipType(item.key)
    return item.priceList?.find(p => p.productId === productId[prop])
  }

  const isPricingActiveTab = pricing => {
    const prop = getVipType(pricing.vipType)
    return productId[prop] === pricing.productId
  }

  const pricingTabClickHandler = pricing => {
    const prop = getVipType(pricing.vipType)
    setProductId({
      ...productId,
      [prop]: pricing.productId,
    })
  }

  const renderPrice = pricing => {
    const { productId, unit, price, originalPrice, key } = pricing
    return (
      <PriceNumWrap className="price-wrap">
        {[
          ProductId.eliteVipOneYear,
          ProductId.superVipOneYear,
          ProductId.globalVipOneYear,
        ].includes(productId) && (
          <div className="original-price">
            原价¥<span>{originalPrice}</span>
          </div>
        )}
        <div className="price">
          <img src={DollarImg} alt="" loading="lazy" />
          <span>{price}</span>
          {key !== PriceType.free && (
            <span className="per">
              /{unit === UnitType.month ? '月' : '年'}
            </span>
          )}
        </div>
        {[
          ProductId.eliteVipOneYear,
          ProductId.superVipOneYear,
          ProductId.globalVipOneYear,
        ].includes(productId) && <div className="count-tag">8.5折</div>}
      </PriceNumWrap>
    )
  }

  const getPriceCardPrimaryColor = item => {
    return item.key === PriceType.globalVip ? '#11C8B2' : '#11C871'
  }

  const isVipCard = item => {
    return [
      PriceType.globalVip,
      PriceType.superVip,
      PriceType.eliteVip,
    ].includes(item.key)
  }

  const normalizeHref = item => {
    const { href } = item
    const { vipType, productId } = getPricing(item)

    return href?.startsWith('http')
      ? `${href}?productId=${productId}&vipType=${vipType}`
      : href
  }

  const [quesUnfoldList, setQuesUnfoldList] = useState(
    questionList.map(() => false)
  )
  return (
    <Layout>
      <SEO
        title="远程控制软件价格-远程连接软件价格-远控软件价格-RayLink远程控制软件"
        keywords="远程控制软件,远控软件,远程软件,远程连接软件"
        description="RayLink远程控制是一款真高清、超流畅的远程控制软件，低至10ms无感延时，媲美本地操作；支持文件传输，多屏远控，手机连接电脑。可远程办公、远程协助运维、远程游戏等"
        ogUrl="price.html/"
      />
      <BannerWrapper>
        {/* 8.12改为开学季活动 add */}
        <BgImage>
          <img className="left" src={EllipseLeftImg} alt="school open season" />
          <img
            className="right"
            src={EllipseRightImg}
            alt="school open season"
          />
          <img
            className="left-mobile"
            src={EllipseMobileLeftImg}
            alt="school open season"
          />
          <img
            className="right-mobile"
            src={EllipseMobileRightImg}
            alt="school open season"
          />
        </BgImage>
        <BannerContent>
          {/* <Title>
            <span>解锁更多功能与服务</span>
            <span className="web-item">，享受</span>
            <div className="more web-item">
              更专业的远程体验
              <img src={moreImg} alt="" loading="lazy" />
            </div>
            <div className="more mobile-item">
              <span>享受</span>
              更专业的远程体验
              <img src={moreImg} alt="" loading="lazy" />
            </div>
          </Title> */}

          {/* 8.12改为开学季活动 */}
          <Title>
            <img
              alt=""
              loading="lazy"
              src={SchoolOpenTitleImg}
              className="elite-title-img"
            />
            <img
              alt=""
              loading="lazy"
              src={SchoolOpenTitleIpadImg}
              className="elite-title-ipad-img"
            />
            <img
              alt=""
              loading="lazy"
              src={SchoolOpenTitlePhoneImg}
              className="elite-title-phone-img"
            />
          </Title>
          <PriceTabsWrapper>
            <PriceTabs tab="personal" />
          </PriceTabsWrapper>

          <PriceWrap>
            {pricingData.map(item => (
              <PriceItem key={item.title} vipType={item.key}>
                <ItemTop
                  primary={getPriceCardPrimaryColor(item)}
                  isVip={isVipCard(item)}
                  className={item.class}
                >
                  <div className="title">{item.title}</div>
                  <div className="text">{item.text}</div>

                  <div
                    className="pricing"
                    ref={el => {
                      pricingTabRef.current.push(el)
                    }}
                  >
                    {/* 免费 */}
                    {item.price && renderPrice(item)}
                    {/* 会员 */}
                    {item.priceList && (
                      <>
                        {item.priceList.length >= 2 && (
                          <div className="pricing-tab">
                            {item.priceList.map(pricing => (
                              <div
                                key={pricing.price}
                                className={`pricing-tab-item ${
                                  isPricingActiveTab(pricing)
                                    ? 'pricing-tab-item__active'
                                    : ''
                                }`}
                                onClick={() => pricingTabClickHandler(pricing)}
                              >
                                {pricing.label}
                              </div>
                            ))}
                          </div>
                        )}
                        {renderPrice(getPricing(item))}
                      </>
                    )}
                  </div>
                  <div className="tip">
                    <span>{item.tip}</span>
                  </div>
                  <PriceBtn
                    primary={getPriceCardPrimaryColor(item)}
                    isLineBtn={!isVipCard(item)}
                    href={normalizeHref(item)}
                    target="_blank"
                  >
                    {item.btnText}
                  </PriceBtn>
                </ItemTop>

                <FeatureWrap isVip={isVipCard(item)}>
                  {item.features.map((feature, index) => (
                    <div className="feature-item" key={index}>
                      <Check
                        className="check-icon"
                        primary={getPriceCardPrimaryColor(item)}
                      />
                      <div className="item-text">
                        {feature?.text ? feature.text : feature}
                      </div>
                      {feature.hot && (
                        <img className="hot" src={HotImg} loading="lazy" />
                      )}
                      {feature?.tip && (
                        <div className="tip">
                          <img
                            className="tip-icon"
                            src={TipImg}
                            alt=""
                            loading="lazy"
                          />
                          <div className="float">
                            {typeof feature.tip === 'string' ? (
                              feature.tip
                            ) : (
                              <>
                                <div>{feature.tip.text}</div>
                                <a
                                  href={feature.tip.href}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {feature.tip.hrefText}
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </FeatureWrap>
              </PriceItem>
            ))}
          </PriceWrap>

          <FullContainer className="full-wrap" list={fullItemList} />
        </BannerContent>
      </BannerWrapper>

      <CompareWrap>
        <h2 className="title">功能服务对比</h2>
        <CompareTable
          titleList={titleList}
          compareList={normalizeTableList(compareList, titleList)}
          className="price-table"
        />
      </CompareWrap>

      <QuestionWrap>
        <h2>常见问题</h2>
        {questionList.map(({ question, answer, tipObj }, idx) => (
          <div key={question} className="question-item">
            <div
              className={`question-item-label ${
                quesUnfoldList[idx] ? 'question-item-label__unfold' : ''
              }`}
              onClick={() => {
                quesUnfoldList[idx] = !quesUnfoldList[idx]
                setQuesUnfoldList([...quesUnfoldList])
              }}
            >
              <div className="question-item-question">{question}</div>
            </div>
            <div className="question-item-content">
              {Array.isArray(answer) ? (
                answer.map(a => (
                  <p key={a} className="question-item-answer">
                    {a}
                  </p>
                ))
              ) : (
                <p className="question-item-answer">{answer}</p>
              )}
              {tipObj && (
                <div className="question-item-tip">
                  <div className="question-item-tip-title">{tipObj.title}</div>
                  {Array.isArray(tipObj.tips) ? (
                    tipObj.tips.map(t => (
                      <p key={t} className="question-item-answer">
                        {t}
                      </p>
                    ))
                  ) : (
                    <p className="question-item-answer">{answer}</p>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </QuestionWrap>
    </Layout>
  )
}
