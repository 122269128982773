import * as React from 'react'

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      stroke={props.primary ?? '#11C871'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.7}
      d="m5 10.333 3.333 3.334L15 7"
    />
  </svg>
)
export default SvgComponent
